import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Box,
    Modal, Button, Typography, Snackbar, Alert
} from '@mui/material';
import { authenticatedFetch, formatDate } from '../utils/utils';

export default function Violations() {
    const [violations, setViolations] = useState({ unresolved: [], resolved: [] });
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedViolation, setSelectedViolation] = useState(null);
    const [generalError, setGeneralError] = useState('');
    const [actionType, setActionType] = useState('');

    useEffect(() => {
        fetchViolations();
    }, []);

    const fetchViolations = async () => {
        setLoading(true);
        try {
            const today = new Date().toISOString().split('T')[0];
            const response = await authenticatedFetch(`/violations/for-school-year/${today}`);
            if (response.ok) {
                const data = await response.json();
                const groupedViolations = groupViolations(data);
                setViolations(groupedViolations);
            } else {
                console.error('Failed to fetch violations');
                setGeneralError('Failed to fetch violations');
            }
        } catch (error) {
            console.error('Error fetching violations:', error);
            setGeneralError('Error fetching violations');
        } finally {
            setLoading(false);
        }
    };

    const groupViolations = (data) => {
        // Sort the violations
        const sortedData = data.sort((a, b) => {
            // First, sort by student name
            const aName = a.student.nickname || a.student.first_name;
            const bName = b.student.nickname || b.student.first_name;
            const nameComparison = aName.localeCompare(bName);
            
            if (nameComparison !== 0) {
                return nameComparison;
            }
            
            // If names are the same, sort by violation date (most recent first)
            return new Date(b.timestamp) - new Date(a.timestamp);
        });

        // Group the sorted violations
        return sortedData.reduce((acc, violation) => {
            if (!violation.complaint && !violation.paid) {
                acc.unresolved.push(violation);
            } else {
                acc.resolved.push(violation);
            }
            return acc;
        }, { unresolved: [], resolved: [] });
    };

    const handleRowClick = (violation) => {
        setSelectedViolation(violation);
        if (!violation.complaint) {
            if (!violation.paid) {
                setActionType('markPaid');
            } else {
                setActionType('unmarkPaid');
            }
            setModalOpen(true);
        }
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setSelectedViolation(null);
        setActionType('');
    };

    const handleAction = async () => {
        try {
            const endpoint = actionType === 'markPaid' ? 'mark-paid' : 'unmark-paid';
            const response = await authenticatedFetch(`/violations/${selectedViolation.id}/${endpoint}`, {
                method: 'PUT'
            });
            if (response.ok) {
                await fetchViolations(); // Refresh the violations list
                handleModalClose();
            } else {
                throw new Error(`Failed to ${actionType === 'markPaid' ? 'mark' : 'unmark'} violation as paid`);
            }
        } catch (error) {
            console.error(`Error ${actionType === 'markPaid' ? 'marking' : 'unmarking'} violation as paid:`, error);
            setGeneralError(`Failed to ${actionType === 'markPaid' ? 'mark' : 'unmark'} violation as paid`);
        }
    };

    const renderViolationRows = (violationsArray, title) => (
        <>
            <TableRow>
                <TableCell colSpan={6} align="center" style={{ background: '#f5f5f5' }}>{title}</TableCell>
            </TableRow>
            {violationsArray.map((violation) => (
                <TableRow 
                    key={violation.id}
                    onClick={() => handleRowClick(violation)}
                    sx={{
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: '#f5f5f5',
                        },
                    }}
                >
                    <TableCell>{violation.student.nickname || `${violation.student.first_name} ${violation.student.last_name}`}</TableCell>
                    <TableCell>{formatDate(violation.timestamp)}</TableCell>
                    <TableCell>{violation.law_code}</TableCell>
                    <TableCell>{violation.description}</TableCell>
                    <TableCell>{violation.complaint ? '✓' : '-'}</TableCell>
                    <TableCell>{violation.paid ? '✓' : '-'}</TableCell>
                </TableRow>
            ))}
        </>
    );

    // Modal style
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <h1>Violations</h1>
            {loading ? (
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.3)',
                    zIndex: 'tooltip',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <CircularProgress />
                </Box>
            ) : (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="violations table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Student</TableCell>
                                <TableCell>Violation Date</TableCell>
                                <TableCell>Law Code</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Complaint?</TableCell>
                                <TableCell>Paid?</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {renderViolationRows(violations.unresolved, "Unresolved")}
                            {renderViolationRows(violations.resolved, "Resolved")}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {actionType === 'markPaid' ? 'Mark Violation as Paid' : 'Unmark Violation as Paid'}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Are you sure you want to {actionType === 'markPaid' ? 'mark' : 'unmark'} this violation as paid?
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleModalClose} sx={{ mr: 1 }}>Cancel</Button>
                        <Button onClick={handleAction} variant="contained" color="primary">
                            {actionType === 'markPaid' ? 'Mark Paid' : 'Unmark Paid'}
                        </Button>
                    </Box>
                </Box>
            </Modal>
            {generalError && (
                <Snackbar open={!!generalError} autoHideDuration={6000} onClose={() => setGeneralError('')}>
                    <Alert onClose={() => setGeneralError('')} severity="error" sx={{ width: '100%' }}>
                        {generalError}
                    </Alert>
                </Snackbar>
            )}
        </div>
    );
}
