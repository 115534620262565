import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Modal, Box, TextField, Button, MenuItem, FormControl, IconButton,
    InputLabel, Select, Typography, Snackbar, Alert,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormHelperText,
    Checkbox, Link as MuiLink
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { format, parse } from 'date-fns';
import { CircularProgress } from '@mui/material';
import { formatTime24To12, formatDate, authenticatedFetch, roundToNearestTenth } from '../utils/utils';

const StudentAttendance = ({ isAdmin }) => {
    const { studentId, date } = useParams();
    const [student, setStudent] = useState(null);
    const [attendanceRecords, setAttendanceRecords] = useState([]);
    const [calendar, setCalendar] = useState(null);
    const [selectedDate, setSelectedDate] = useState(date ? parse(date, 'yyyy-MM-dd', new Date()) : new Date());
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        date: '',
        signed_in_at: '',
        signed_out_at: '',
        absence_reason: 'present',
        absence_reason_other_description: '',
        hours_credited: 0
    });
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [editingMultiple, setEditingMultiple] = useState(false);
    const [lastCheckedIndex, setLastCheckedIndex] = useState(null);

    // Move isSelected definition here
    const isSelected = useCallback((record) => {
        return selectedRecords.some(r => r.date === record.date);
    }, [selectedRecords]);

    useEffect(() => {
        fetchStudentData();
    }, [studentId, selectedDate]);

    const fetchStudentData = async () => {
        setLoading(true);
        try {
            const response = await authenticatedFetch(`/students/${studentId}/attendance/${selectedDate.toISOString()}`);
            if (!response.ok) throw new Error('Failed to fetch student data and attendance records');
            const data = await response.json();
            setStudent(data.student);
            setAttendanceRecords(data.attendance);
            setCalendar(data.calendar);
        } catch (error) {
            setAlert({ message: error.message, severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleCheckboxChange = useCallback((record, index, event) => {
        event.stopPropagation(); // Prevent row click event

        setSelectedRecords(prevSelected => {
            let newSelected = [...prevSelected];
            
            if (event.nativeEvent.shiftKey && lastCheckedIndex !== null) {
                const start = Math.min(lastCheckedIndex, index);
                const end = Math.max(lastCheckedIndex, index);

                // Determine if we're selecting or deselecting
                const isSelecting = !isSelected(record);
                
                for (let i = start; i <= end; i++) {
                    const currentRecord = attendanceRecords[i];
                    if (!currentRecord.holiday) {
                        const alreadySelected = newSelected.some(r => r.date === currentRecord.date);
                        if (isSelecting && !alreadySelected) {
                            newSelected.push(currentRecord);
                        } else if (!isSelecting && alreadySelected) {
                            newSelected = newSelected.filter(r => r.date !== currentRecord.date);
                        }
                    }
                }
            } else {
                const recordIndex = newSelected.findIndex(r => r.date === record.date);
                if (recordIndex !== -1) {
                    newSelected = newSelected.filter(r => r.date !== record.date);
                } else {
                    newSelected.push(record);
                }
            }
            
            console.log('New selected records:', newSelected.map(r => r.date));
            return newSelected;
        });

        setLastCheckedIndex(index);
    }, [lastCheckedIndex, attendanceRecords, isSelected]);

    const handleRowClick = (record) => {
        if (selectedRecords.length > 0) {
            handleEditMultiple();
        } else {
            setFormData({
                ...record,
                date: formatDate(new Date(record.date)),
                signed_in_at: record.signed_in_at || '',
                signed_out_at: record.signed_out_at || '',
                absence_reason: record.absence_reason || 'present',
                absence_reason_other_description: record.absence_reason_other_description || '',
                hours_credited: record.hours_credited !== null ? record.hours_credited.toString() : 0
            });
            setFormErrors({});
            setEditingMultiple(false);
            setModalOpen(true);
        }
    };

    const handleEditMultiple = () => {
        setFormData({
            date: '',
            signed_in_at: '',
            signed_out_at: '',
            absence_reason: 'present',
            absence_reason_other_description: '',
            hours_credited: ''
        });
        setFormErrors({});
        setEditingMultiple(true);
        setModalOpen(true);
    };

    const currentTime = () => {
        return new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });
    }

    const handleEditChange = (e) => {
        const { name, value } = e.target;

        // If the absence reason is changed, set the sign in and out times accordingly
        if (name === 'absence_reason') {
            if (value === 'present' && formData.absence_reason !== 'present') {
                setFormData({ ...formData, absence_reason: value, signed_in_at: currentTime(), signed_out_at: '' });
            }
            else if (value !== 'present' && formData.absence_reason === 'present') {
                setFormData({ ...formData, absence_reason: value, signed_in_at: '', signed_out_at: '' });
            }
            else {
                setFormData({ ...formData, [name]: value });
            }
        }
        else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const clearField = (fieldName) => {
        setFormData({ ...formData, [fieldName]: '' });
    };

    const validateForm = () => {
        const errors = {};
        if (!formData.signed_in_at && formData.absence_reason === "present") errors.signed_in_at = "Sign-in time is required for students that aren't absent.";
        if (!formData.signed_in_at && formData.signed_out_at) errors.signed_out_at = "Can't sign out without signing in first.";
        if (formData.signed_in_at && formData.absence_reason !== "present") errors.signed_in_at = "Sign-in time is not allowed for absent students.";
        if (formData.signed_out_at && formData.absence_reason !== "present") errors.signed_out_at = "Sign-out time is not allowed for absent students.";
        if (formData.signed_in_at && formData.signed_out_at && formData.signed_in_at > formData.signed_out_at) errors.signed_out_at = "Sign-out time must be after sign-in time.";
        if (formData.hours_credited < 0 || formData.hours_credited > 24) errors.hours_credited = "Hours credited must be between 0 and 24";
        return errors;
    };

    const saveAttendance = async (e) => {
        e.preventDefault();
        const errors = validateForm();
        setFormErrors(errors);
        if (Object.keys(errors).length > 0) return;

        setLoading(true);
        try {
            const recordsToUpdate = editingMultiple ? selectedRecords : [formData];
            
            for (let record of recordsToUpdate) {
                const updatedRecord = {
                    ...record,
                    ...formData,
                    date: record.date, // Preserve the original date
                    hours_credited: Number(formData.hours_credited) || 0,
                    absence_reason: formData.absence_reason || null,
                    absence_reason_other_description: formData.absence_reason_other_description || null,
                    signed_in_at: formData.signed_in_at || null,
                    signed_out_at: formData.signed_out_at || null
                };
                await saveOrUpdateRecord(updatedRecord);
            }

            setModalOpen(false);
            fetchStudentData();
            setAlert({ message: 'Attendance record(s) saved successfully', severity: 'success' });
            setSelectedRecords([]);
            setEditingMultiple(false);
        } catch (error) {
            setAlert({ message: error.message, severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const saveOrUpdateRecord = async (record) => {
        const url = `/attendance/record/${studentId}/${record.date}`;
        
        const response = await authenticatedFetch(url, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(record)
        });

        if (!response.ok) {
            throw new Error('Failed to save attendance record');
        }
    };

    const handleDeleteClick = () => {
        setShowDeleteConfirm(true);
    };

    const deleteAttendance = async () => {
        setLoading(true);
        try {
            if (selectedRecords.length > 0) {
                // Deleting multiple records
                const existingRecords = selectedRecords.filter(record => record.id);
                for (let record of existingRecords) {
                    await deleteRecord(record.date);
                }
                const deletedCount = existingRecords.length;
                const skippedCount = selectedRecords.length - existingRecords.length;
                let message = `${deletedCount} attendance record(s) deleted successfully`;
                if (skippedCount > 0) {
                    message += `. ${skippedCount} non-existent record(s) skipped.`;
                }
                setAlert({ message, severity: 'success' });
            } else {
                // Deleting single record
                if (formData.id) {
                    await deleteRecord(formData.date);
                    setAlert({ message: 'Attendance record deleted successfully', severity: 'success' });
                } else {
                    setAlert({ message: 'No existing record to delete', severity: 'info' });
                }
            }
            setModalOpen(false);
            fetchStudentData();
            setAlert({ message: 'Attendance record(s) deleted successfully', severity: 'success' });
            setSelectedRecords([]);
            setEditingMultiple(false);
        } catch (error) {
            setAlert({ message: 'Failed to delete attendance record(s)', severity: 'error' });
        } finally {
            setLoading(false);
            setShowDeleteConfirm(false);
        }
    };

    const deleteRecord = async (date) => {
        const response = await authenticatedFetch(`/attendance/record/${studentId}/${date}`, {
            method: 'DELETE'
        });
        if (!response.ok) throw new Error('Failed to delete attendance record');
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
    };

    const absenceReasonLabel = (absence_reason) => {
        switch (absence_reason) {
            case 'sick': return 'Sick';
            case 'personal_day': return 'Personal Day';
            case 'excused': return 'Absent - Granted Hours';
            case 'work_study': return 'Absent - Work Study';
            case 'unexcused': return 'Absent - Hours Not Required';
            case 'present': return 'Present';
            case 'suspended': return 'Suspended - Granted Hours';
            default: return 'No Attendance Record';
        }
    };

    const renderTableRow = (record, index) => {
        if (record.holiday) {
            return (
                <TableRow key={record.date} sx={{ backgroundColor: '#f5f5f5' }}>
                    <TableCell colSpan={6} align="center" sx={{ color: 'text.secondary' }}>
                        {record.holiday}
                    </TableCell>
                </TableRow>
            );
        }

        return (
            <TableRow
                key={record.date}
                id={`attendance-row-${record.date}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                onClick={() => handleRowClick(record)}
            >
                <TableCell padding="checkbox">
                    <Checkbox
                        checked={isSelected(record)}
                        onChange={(event) => handleCheckboxChange(record, index, event)}
                        onClick={(e) => e.stopPropagation()}
                    />
                </TableCell>
                <TableCell component="th" scope="row">
                    {record.date.split('-').slice(1, 3).join('/')}
                </TableCell>
                <TableCell>{record.signed_in_at ? formatTime24To12(record.signed_in_at) : '—'}</TableCell>
                <TableCell>{record.signed_out_at ? formatTime24To12(record.signed_out_at) : '—'}</TableCell>
                <TableCell>{record.absence_reason !== 'present' ? absenceReasonLabel(record.absence_reason) : '—'}</TableCell>
                <TableCell>{record.hours_credited !== null ? roundToNearestTenth(record.hours_credited) : '—'}</TableCell>
            </TableRow>
        );
    };

    const scrollToToday = useCallback(() => {
        const today = new Date().toISOString().split('T')[0];
        const todayElement = document.getElementById(`attendance-row-${today}`);
        if (todayElement) {
            todayElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, []);

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
                <Typography variant="h5">
                    {student && calendar 
                        ? `${student.nickname} Attendance: ${new Date(calendar.first_day).getFullYear()} - ${new Date(calendar.last_day).getFullYear()}` 
                        : 'Loading...'}
                </Typography>
                <MuiLink
                    component="button"
                    variant="body2"
                    onClick={scrollToToday}
                    sx={{ mt: 1, cursor: 'pointer' }}
                >
                    Go to Today
                </MuiLink>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="student attendance table">
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox"></TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>In</TableCell>
                            <TableCell>Out</TableCell>
                            <TableCell>Absent?</TableCell>
                            <TableCell>Hours Credited</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {attendanceRecords && attendanceRecords.length > 0 ? (
                            attendanceRecords.map((record, index) => renderTableRow(record, index))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={6} align="center">No attendance records found</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal
                open={modalOpen}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        setModalOpen(false);
                        setEditingMultiple(false);
                        setSelectedRecords([]);
                    }
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography variant="h6" sx={{ textAlign: "center" }}>
                        {editingMultiple 
                            ? `Edit ${selectedRecords.length} Attendance Records` 
                            : (student ? `Edit Attendance for ${student.nickname}` : 'Edit Attendance Record')}
                    </Typography>
                    <Box component="form" noValidate sx={{ mt: 1 }}>
                        {!editingMultiple && (
                            <TextField
                                margin="normal"
                                fullWidth
                                name="date"
                                label="Date"
                                type="date"
                                value={formData.date}
                                onChange={handleEditChange}
                                disabled
                            />
                        )}
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                margin="normal"
                                fullWidth
                                name="signed_in_at"
                                label="Signed In Time"
                                type="time"
                                InputLabelProps={{ shrink: true }}
                                value={formData.signed_in_at}
                                onChange={handleEditChange}
                                error={!!formErrors.signed_in_at}
                                helperText={formErrors.signed_in_at}
                            />
                            <IconButton onClick={() => clearField('signed_in_at')}><ClearIcon /></IconButton>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                margin="normal"
                                fullWidth
                                name="signed_out_at"
                                label="Signed Out Time"
                                type="time"
                                InputLabelProps={{ shrink: true }}
                                value={formData.signed_out_at}
                                onChange={handleEditChange}
                                error={!!formErrors.signed_out_at}
                                helperText={formErrors.signed_out_at}
                            />
                            <IconButton onClick={() => clearField('signed_out_at')}><ClearIcon /></IconButton>
                        </Box>
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="absence-reason-label">Absence Reason</InputLabel>
                            <Select
                                labelId="absence-reason-label"
                                id="absence_reason"
                                name="absence_reason"
                                value={formData.absence_reason}
                                label="Absence Reason"
                                onChange={handleEditChange}
                                error={!!formErrors.absence_reason}
                            >
                                <MenuItem value="present">{absenceReasonLabel('present')}</MenuItem>
                                <MenuItem value="sick">{absenceReasonLabel('sick')}</MenuItem>
                                <MenuItem value="personal_day">{absenceReasonLabel('personal_day')}</MenuItem>
                                <MenuItem value="excused">{absenceReasonLabel('excused')}</MenuItem>
                                <MenuItem value="work_study">{absenceReasonLabel('work_study')}</MenuItem>
                                <MenuItem value="unexcused">{absenceReasonLabel('unexcused')}</MenuItem>
                                <MenuItem value="suspended">{absenceReasonLabel('suspended')}</MenuItem>
                            </Select>
                            {formErrors.absence_reason && <FormHelperText style={{ color: 'red' }}>{formErrors.absence_reason}</FormHelperText>}
                        </FormControl>
                        {formData.absence_reason !== 'present' && (
                            <TextField
                                margin="normal"
                                fullWidth
                                name="absence_reason_other_description"
                                label="Absence Details"
                                value={formData.absence_reason_other_description}
                                onChange={handleEditChange}
                            />
                        )}
                        <TextField
                            margin="normal"
                            fullWidth
                            name="hours_credited"
                            label="Hours Credited"
                            type="number"
                            value={formData.hours_credited}
                            onChange={handleEditChange}
                            inputProps={{ min: 0, step: 0.1 }}  // Allows decimal values
                            error={!!formErrors.hours_credited}
                            helperText={formErrors.hours_credited}
                        />
                        <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                            <Box>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={saveAttendance}
                                    sx={{ mr: 1 }}
                                >
                                    Save
                                </Button>
                                <Button
                                    onClick={() => {
                                        setModalOpen(false);
                                        setEditingMultiple(false);
                                        setSelectedRecords([]);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Box>
                            <Box>
                                <Button
                                    onClick={handleDeleteClick}
                                    style={{ color: '#f44336' }}
                                    variant="text"
                                >
                                    Delete
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>

            <Dialog
                open={showDeleteConfirm}
                onClose={() => setShowDeleteConfirm(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {selectedRecords.length > 0
                            ? `Are you sure you want to delete these ${selectedRecords.length} attendance records?`
                            : "Are you sure you want to delete this attendance record?"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowDeleteConfirm(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={deleteAttendance} color="error" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={!!alert}
                autoHideDuration={6000}
                onClose={() => setAlert(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setAlert(null)} severity={alert?.severity || 'info'} sx={{ width: '100%' }}>
                    {alert?.message}
                </Alert>
            </Snackbar>

            {loading && (
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.3)',
                    zIndex: 'tooltip',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <CircularProgress />
                </Box>
            )}
        </>
    );
};

export default StudentAttendance;
